<template>
<div> 
    <v-btn id="pwaInstallBtn" color="success">インストール</v-btn>
    <sponsored-panel />
    <div class="login-container pa-6">
        <div class="text-h3 text-center logo-text mt-3" >建設向け</div>
        <div class="text-h3 text-center logo-text mb-3">勤怠管理</div>
        <div style="z-index:100;">
            <v-form v-if="$store.state.privateLogin" @submit.prevent v-model="formValid" class="my-3">
                <v-text-field 
                    type="text" 
                    label="PIN" 
                    v-model="formData.userPin" 
                    outlined dense
                    :rules="[
                        v => (!!v && v.length >= 4) || 'PINを入れてください'
                    ]"
                />
                <v-btn 
                    class="primary font-weight-bold headline"
                    width="100%"
                    type="button"
                    :disabled="!formValid" 
                    @click="submitData()"
                >
                    ログイン
                </v-btn>
            </v-form>
            <div class="d-flex justify-center">
                <v-card 
                    style="width:45%;"
                    class="primary mr-3"
                    @click="displayQRCodeDialog = !displayQRCodeDialog">
                    <v-card-title class="d-flex justify-center">
                        <v-icon>mdi-qrcode</v-icon>
                        <v-icon size=56>mdi-camera</v-icon>
                    </v-card-title>
                    <v-card-text class="font-weight-bold text-h7 d-flex justify-center" style="white-space: nowrap;">
                        カメラで読み込み
                    </v-card-text>
                </v-card>
                <v-card 
                    style="width:45%;"
                    class="primary"
                    @click="initUpload()">
                    <v-card-title class="d-flex justify-center">
                        <v-icon>mdi-qrcode</v-icon>
                        <v-icon size=56>mdi-image</v-icon>
                    </v-card-title>
                    <v-card-text class="font-weight-bold text-h7 d-flex justify-center" style="white-space: nowrap;">
                        写真で読み込み
                    </v-card-text>
                </v-card>
            </div>
        </div>
        <div v-if="error" style="text-align:center; color:red">読み込みませんでした。もう一度操作を行ってください。
        </div>
        <div style="text-align:center;">
            <img id="previewImg" width=50% >
        </div>
    </div>
    <wave />
    <wave :position="'top'"/>
    <v-dialog v-if="displayQRCodeDialog" v-model="displayQRCodeDialog" persistent fullscreen>
        <v-card>
            <div class="ma-3">
                <qr-code-reader @gotResult="initQrResult"/>
            </div>
            <div class="caption text-center">QRコードを提示してください。</div>
            <div class="caption text-center">できるだけ明るい環境で操作してください。</div>
            <v-card-actions class="d-flex justify-center">
                <v-btn color="error" @click="displayQRCodeDialog = !displayQRCodeDialog">閉じる</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <version-footer/>
    
    <input id="fileUpload" type="file" @change="initPreview" accept="image/*" hidden>
</div>
</template>


<script>
import qrCodeReader from '../components/generalComponents/qrCodeReader.vue'
import qrCodeDecoder from 'qrcode-decoder';
import VersionFooter from '../components/generalComponents/VersionFooter.vue'
import Wave from '../components/generalComponents/Wave.vue'
import SponsoredPanel from '../components/generalComponents/SponsoredPanel.vue'
let deferredPrompt;
window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
    // Update UI notify the user they can install the PWA
    document.getElementById('pwaInstallBtn').addEventListener("click", function(mouseEvent) {
        // you should not use the MouseEvent here, obviously
        deferredPrompt.prompt();
    });
    document.getElementById('pwaInstallBtn').style.display = "block"
});
export default {
    components:{
        qrCodeReader,
        qrCodeDecoder,
        VersionFooter,
        Wave,
        SponsoredPanel
    },
    data(){
        return{
            formValid:false ,
            error : null,
            displayQRCodeDialog: false,
            formData:{
                userPin:null,
                privateLogin: this.$store.state.privateLogin
            },
        }
    },
    methods:{
        initPreview(input){
            this.error = null
            if (input.target.files && input.target.files[0]) {
                var reader = new FileReader();
                reader.onload = (e)=>{
                    let targetImg = document.getElementById("previewImg")
                    targetImg.setAttribute("src", e.target.result)
                    let qr = new qrCodeDecoder();
                    setTimeout(()=>{
                        qr.decodeFromImage(targetImg).then((res) => {
                            if(!res) return this.error = true
                            this.initQrResult(res.data)
                        });
                    },1000)
                }        
                reader.readAsDataURL(input.target.files[0]); // convert to base64 string
            }
        },
        initUpload(){
            document.getElementById('fileUpload').click()
        },
        async submitData(){
            try{
                this.$store.commit('setLoadingScreen',{display:true})
                let response = await this.$store.dispatch('loginClient', this.formData)
                if(response && response.data){
                    await this.$store.commit('setUserProfile', response.data)
                    this.$store.commit('setSnack',{
                        display:true,
                        iconColor:"success",
                        icon:"mdi-alert-circle",
                        text: "ようこそ　"+response.data.name+"　様！" 
                    })
                    this.$store.commit('setLoadingScreen',{display:false})
                    this.$router.replace('/cDash')
                }
            }catch(err){
                if(!this.$store.state.isProduction)
                    console.log(err)
                this.$store.commit('setSnack',{
                    display:true,
                    iconColor:"red",
                    icon:"mdi-alert-circle",
                    text: err.response? err.response.data  :"エラーが発生しました。" 
                })
                this.$store.commit('setLoadingScreen',{display:false})
            }
        },
        decipherCerceisCode(code){
            const codeArr = code.split("-")
            let pin = ""
            let gKey = []
            for(let i = 0 ; i<4 ; i++){
                pin += codeArr[i].slice(-1)
                gKey.push(codeArr[i].slice(0,-1))
            }
            const remainCheck = codeArr[4].split("$")
            if(remainCheck.length >= 2){
                pin += remainCheck[1]
            }
            gKey = gKey.concat(remainCheck[0])
            return {
                p : pin,
                g : gKey.join("-")
            }
        },
        initQrResult(e){
            const result = this.decipherCerceisCode(e)
            this.displayQRCodeDialog = false
            this.formData["userPin"] = result.p
            this.formData["qrPassword"] = result.g
            this.submitData()
        }
    }  
}
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=RocknRoll+One&display=swap');
.logo-text{
    font-family: 'RocknRoll One', sans-serif !important;
}
.login-container{
    position: absolute;
    width: 100%;
    height: 100vh;
    display: grid;
    place-content: center;
    place-items: center;
}
#pwaInstallBtn{
    display:none;
    position:absolute;
    top: 1em;
    left: 1em;
    z-index: 5;
}

</style>