<template>
    <div @click="clickCount++" id="versionFooter">{{$store.state.version}}</div>
</template>

<script>
export default({
    data:()=>({
        clickCount:0,
        clickTimer:null
    }),
    mounted(){
        const footer = document.getElementById('versionFooter')
        if(this.$vuetify.theme.isDark) footer.style.color = 'black'
        else footer.style.color = 'white'
    },
    watch:{
        clickCount(){
            if(this.clickCount > 0){
                clearTimeout(this.clickTimer)
                this.clickTimer = setTimeout(()=>{
                    this.clickCount = 0
                },2000)
                if(this.clickCount >= 6){
                    this.$router.push('/al')
                }
            }
        }
    },
})
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');
#versionFooter{
    font-family: 'Montserrat', sans-serif;
    position:absolute;
    font-size:80%;
    opacity: .5;
    bottom: 3em;
    z-index: 5;
    left: 50%;
    transform: translateX(-50%);
}
</style>