<template>
    <div class="qr-con">
        <p class="error">{{ error }}</p>
        <v-progress-circular
            class="progressCircular"
            v-if="!loaded"
            indeterminate
            color="primary"
        ></v-progress-circular>
        <qrcode-stream @decode="onDecode" @init="onInit" />
    </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
    components: { QrcodeStream },
    data(){
        return{
            result: '',
            error: '',
            loaded:false
        }
    },
    watch:{
        result(){
            if(this.result){
                this.$emit("gotResult",this.result)
            }
                
        }
    },
    methods:{
        onDecode (result) {
            this.result = result
        },
        async onInit (promise) {
            
            try {
                await promise
                this.loaded = true
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                this.error = "ERROR: カメラを使う許可が必要です"
                } else if (error.name === 'NotFoundError') {
                this.error = "ERROR: このデバイスにはカメラがありません"
                } else if (error.name === 'NotSupportedError') {
                this.error = "ERROR: secure context required (HTTPS, localhost)"
                } else if (error.name === 'NotReadableError') {
                this.error = "ERROR: カメラはもう使われていますか?"
                } else if (error.name === 'OverconstrainedError') {
                this.error = "ERROR: カメラが対応していません"
                } else if (error.name === 'StreamApiNotSupportedError') {
                this.error = "ERROR: ブラウザーが対応されていません"
                }
            }
        }
    },
}
</script>


<style scoped>
.qr-con{
    position:relative;
    width: 100%;
    height: 75vh;
}
.progressCircular{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}
</style>