<template>
    <div id="waveCon" :style="waveConStlye">
        <div class="wave" :style="waveStyle"></div>
        <div class="wave" :style="waveStyle"></div>
        <div class="wave" :style="waveStyle"></div>
    </div>
</template>

<script>
export default {
    props:["position"],
    data:()=>({
        waveStyle:{
            filter: "brightness(0)",
        },
        waveConStlye:{
            transform: "",
        }
    }),
    mounted(){
        if(this.$vuetify.theme.isDark) this.waveStyle.filter = "brightness(100%)"
        else this.waveStyle.filter = "brightness(0)" 
        if(this.position === 'top'){
            this.waveConStlye.transform = "rotate(180deg)"
        }
    },
}
</script>

<style scoped>
.wave {
    -webkit-animation: wave calc(var(--speed, 0) * 1s) infinite linear;
    animation: wave calc(var(--speed, 0) * 1s) infinite linear;
    background-image: url("../../assets/wave--infinite.svg");
    background-size: 100% 100%;
    bottom: 0;
    height: calc(var(--height, 0) * 1vh);
    left: 0;
    opacity: var(--opacity);
    position: fixed;
    width: calc(var(--width, 0) * 1vw);
}
@-webkit-keyframes wave {
    to {
    -webkit-transform: translate(-50%, 0);
  }
}
@keyframes wave {
  to {
    transform: translate(-50%, 0);
  }
}
.wave:nth-of-type(1) {
  --height: 18;
  --opacity: .2;
  --speed: 120;
  --width: 800;
}
.wave:nth-of-type(2) {
  --height: 12;
  --opacity: 0.6;
  --speed: 60;
  --width: 800;
}
.wave:nth-of-type(3) {
  --height: 6;
  --opacity: 1;
  --speed: 30;
  --width: 800;
}
</style>