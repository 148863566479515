<template>
    <div class="sponsoredCon">
        <img
            src="@/assets/192.png"
            width="20px"
        >
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.sponsoredCon{
    position:fixed;
    z-index: 5;
    right:1em;
    top:1em;
}
.sponsoredCon img{
    border-radius:10%;
}

</style>